import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuErrorService } from '@app/core/services/au-error-service';
import { IDashboardState } from '@app/pages/dashboard/state/dashboard.reducer';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { sessionQuery } from '../graphql/file-import.queries';
import { ISessionImports } from '../interfaces/file-import.interfaces';
import { EMPTY, catchError, map, pluck, take } from 'rxjs';
import { detailedViewTitleChanged } from '@app/pages/dashboard/state/dashboard.actions';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class ImportSessionResolver {
	constructor(
		private store$: Store<IDashboardState>,
		private apollo: Apollo,
		private auErrorService: AuErrorService,
		private datePipe: DatePipe
	) {}

	resolve(route: ActivatedRouteSnapshot) {
		return this.apollo
			.query<ISessionImports>({
				query: sessionQuery,
				variables: {
					pkIn: route.params.id,
				},
			})
			.pipe(
				take(1),
				pluck('data'),
				map(data => {
					const item = data.fileEntitiesImports.edges[0].node;
					this.store$.dispatch(
						detailedViewTitleChanged({
							payload: item.name,
						})
					);
					return `${this.datePipe.transform(item.created, 'dd MMM, yyyy HH:mm')} ${
						item.name
					}`;
				}),
				catchError(err => {
					this.auErrorService.goToErrorPageAndShowError(err);
					return EMPTY;
				})
			);
	}
}
